
import { defineComponent, ref, reactive, inject, computed, toRef } from 'vue'
import DateRangePicker from '@/views/components/dateRangePicker.vue'
import moment from 'moment'
import { device, employee, modalInstance, notifyInstance } from '@/type'
import axios from '@/http'
import usePagination from '@/plugins/usePagination'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import exportExcel from '@/plugins/exportExcel'
import helper from '@/helper'

moment.locale('zh-tw')

interface pagination {
  getData: () => void
  go: (page: number) => void
  next: () => void
  pre: () => void
}

interface permissionRecord {
  deviceId: string
  employeeNo: string
  action: string
  createdAt: string
}

export default defineComponent({
  components: {
    DateRangePicker
  },
  setup() {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const globalVariable = computed(() => store.getters.globalVariable)
    const aclItemMap = computed(() => globalVariable.value.aclItems)

    const employees = ref(new Array<employee>())
    const devices = ref(new Array<device>())

    const exportType = ref('Permission')
    const status = ref(true)

    const filterEmployee = computed(() => {
      return employees.value.filter((employee: employee) => {
        return Boolean(employee.status) === status.value
      })
    })

    let pagination: {
      next: () => any
      pre: () => any
      go: (goPage: number) => any
      getData: () => any
    } | null = null

    const paginationData = reactive({
      page: 1,
      pageSize: 10,
      lastPage: 1,
      total: 0,
      permissionRecords: ref(new Array<permissionRecord>())
    })

    const range = reactive({
      start: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
      end: moment().endOf('day').format('YYYY-MM-DDTHH:mm')
    })

    const selectedEmployee = ref('')

    const paginationSetting = reactive({
      page: toRef(paginationData, 'page'),
      pageSize: toRef(paginationData, 'pageSize'),
      api: `/employeeActionLog/${storeId.value}`,
      payload: {
        startTime: toRef(range, 'start'),
        endTime: toRef(range, 'end'),
        employeeNo: selectedEmployee
      }
    })

    function init() {
      const initEmployee = axios
        .get(`/employees/${storeId.value}`)
        .then((res) => {
          const data = res.data
          employees.value = data.employees
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })

      const initDevice = axios
        .get(`/devices/${storeId.value}`)
        .then((res) => {
          const data = res.data
          devices.value = data.devices
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })

      const initPagination = usePagination(paginationSetting).then((res) => {
        pagination = res
      })

      Promise.all([initEmployee, initDevice, initPagination]).then(function () {
        getData()
      })
    }

    function formatNumber(number: number) {
      return number > 0
        ? `$${number.toLocaleString('en-US')}`
        : `- $${Math.abs(number).toLocaleString('en-US')}`
    }

    function formatDateTime(time: any) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }

    function getEmployeeName(employeeNo: string) {
      const employee = employees.value.find((employee: employee) => {
        return employee.no === employeeNo
      })
      if (employee !== undefined) {
        return employee.name
      }
      return ''
    }

    function getDeviceName(deviceId: number) {
      const device = devices.value.find((device: device) => {
        return parseInt(device.id) === deviceId
      })
      if (device !== undefined) {
        return device.name
      }
      return ''
    }

    function updateRange(updateRange: any) {
      range.start = moment(updateRange.start).format('YYYY-MM-DDTHH:mm')
      range.end = moment(updateRange.end).format('YYYY-MM-DDTHH:mm')
      goPage(1)
    }

    init()

    function nextPage() {
      const fetchData = pagination?.next()
      fetchData.then(() => {
        getData()
      })
    }

    function prePage() {
      const fetchData = pagination?.pre()
      fetchData.then(() => {
        getData()
      })
    }

    function goPage(page: number) {
      const fetchData = pagination?.go(page)
      fetchData.then(() => {
        getData()
      })
    }

    function getData() {
      const data = pagination?.getData()
      paginationData.page = data.currentPage
      paginationData.lastPage = data.lastPage
      paginationData.pageSize = data.pageSize
      paginationData.total = data.total
      paginationData.permissionRecords = data.items
    }

    function changeEmployee(employeeNo: any) {
      selectedEmployee.value = employeeNo
      goPage(1)
    }

    function statusChange() {
      status.value = !status.value
      selectedEmployee.value = ''
      goPage(1)
    }

    function exportExcelClick() {
      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content: '權限不足</br>' + '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportType.value,
          storeId.value,
          {
            startTime: range.start,
            endTime: range.end,
            employeeNo: selectedEmployee.value
          },
          modal,
          notify,
          router
        )
      }
    }

    return {
      updateRange,
      range,
      formatNumber,
      pagination,
      paginationData,
      nextPage,
      prePage,
      goPage,
      getEmployeeName,
      getDeviceName,
      formatDateTime,
      aclItemMap,
      selectedEmployee,
      employees,
      filterEmployee,
      status,
      changeEmployee,
      statusChange,
      exportExcelClick
    }
  }
})
